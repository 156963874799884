import React from "react";
import PropTypes from "prop-types";
import classes from "./Input.module.css";

const Input = ({ id, type, label }) => {
  return (
    <div className={classes.input}>
      {label && <label htmlFor={id || label}>{label}</label>}
      <input id={id || label} type={type || "text"} />
      <small></small>
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
};

export default Input;
