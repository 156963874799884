import React from "react";
import {
  Contact,
  Cta,
  Features,
  Section,
  Testimonials,
} from "../components/Home";
import InfoImage from "./../assets/images/close-affordable.png";
import ScheduleCareImage from "./../assets/images/schedule-care-blur.png";
import MonitorVitalsImage from "./../assets/images/monitor-vitals-blur.png";
import MedsAndDevicesImage from "./../assets/images/medications-and-devices-blur.png";
import RemindersImage from "./../assets/images/reminders-blur.png";
import UrgentCareImage from "./../assets/images/urgent-care-blur.png";
import CtaBottom from "../components/Home/CtaBottom/CtaBottom";
import GetStarted from "../components/Home/GetStarted/GetStarted";

const Home = () => {
  return (
    <>
      <Cta />
      <GetStarted
        title="Close, Affordable & Tailor-made care for you"
        description="We are aimed at bringing affordable healthcare closer to you than ever, with just the click of a button."
        imageUrl={InfoImage}
        reverse
      />
      <Features
        title="OUR FEATURES"
        subTitle="Syren provides features that has your utmost care in mind"
      >
        <Section
          title="Schedule Care"
          description="Why experience delay when seeking healthcare? With syren, you can access quality and affordable healthcare at your convenience."
          list={[
            "Home visit",
            "Diagnostic Center Appointment",
            "Medical Specialist Appointment",
            "Ambulance Services",
          ]}
          imageUrl={ScheduleCareImage}
        />
        <Section
          title="Monitor Your Vitals"
          description="We help you keep records of your checks/ health and work with you to achieve stable healthy levels. Share your records with family members and your Physician in real time."
          list={[
            "Blood Pressure record",
            "Blood Sugar record",
            "Cholesterol records",
            "Menstrual Cycle",
          ]}
          imageUrl={MonitorVitalsImage}
          reverse
        />
        <Section
          title="Medications and Devices"
          description="Purchase over the counter and prescription medications at the best prices."
          list={[
            "Purchase medications at the best prices",
            "Schedule delivery of your routine medications",
            "Purchase medical devices",
          ]}
          imageUrl={MedsAndDevicesImage}
        />
        <Section
          title="Reminders"
          description="Purchase over the counter and prescription medications at the best prices."
          list={[
            "Reminder to use routine medications",
            "Reminder to check Blood pressure, sugar, etc",
            "Reminder to check in for Appointments",
          ]}
          imageUrl={RemindersImage}
          reverse
        />
        <Section
          title="Urgent Care"
          description="Having an emergency? Hit the panic button and an emergency response team would be dispatched to you."
          list={[
            "Fast and reliable service",
            "Fully equipped to meet your needs",
            "Efficient and effective response from our team",
          ]}
          imageUrl={UrgentCareImage}
        />
      </Features>
      <Testimonials
        title="TESTIMONIALS"
        subTitle="What our users say about us"
      />
      <Contact
        title="CONTACT"
        subTitle="Don’t hesitate to contact us if you’re experiencing any issues"
      />
      <CtaBottom />
    </>
  );
};

export default Home;
