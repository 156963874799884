import React from "react";
import PropTypes from "prop-types";
import classes from "./SubTitle.module.css";

const SubTitle = ({text, className}) => {
  let classNames = classes["sub-title"];
  if(className) {
    classNames += ` ${className}`
  }
  return <h1 className={classNames}>{text}</h1>;
};

SubTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default SubTitle;
