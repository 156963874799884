import React from "react";
import PropTypes from "prop-types";
import classes from "./Button.module.css";

const Button = ({ children, label, icon, white, gapRight, gapLeft }) => {
  let btnClasses = classes.button;

  if (white) {
    btnClasses += " " + classes.white;
  }
  return (
    <button
      style={{
        marginLeft: gapLeft ? gapLeft : 0,
        marginRight: gapRight ? gapRight : 0,
      }}
      className={btnClasses}
    >
      {icon ? <span className={classes.icon}>{icon}</span> : null}{label || children}
    </button>
  );
};

Button.propTypes = {
  gapRight: PropTypes.string,
  gapLeft: PropTypes.string,
  white: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};

export default Button;
