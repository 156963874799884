import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  RootLayout,
  HomePage,
  TermsConditionsPage,
  PrivacyPolicyPage,
} from "./pages";
import ScrollToTop from "./wrappers/ScrollToTop";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ScrollToTop>
        <RootLayout />
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "terms-conditions",
        element: <TermsConditionsPage />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
