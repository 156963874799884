import React from "react";
import PropTypes from "prop-types";
import classes from "./Features.module.css";
import { SubTitle, Title } from "../../UI";

const Features = ({ children, title, subTitle }) => {
  return (
    <div className={classes.features}>
      <Title text={title} />
      <SubTitle text={subTitle} className="mb-0" />
      {children}
    </div>
  );
};

Features.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Features;
