import React from "react";
import PropTypes from "prop-types";
import classes from "./GetStarted.module.css";
import Button from "../../UI/Button/Button";

const GetStarted = ({ title, description, imageUrl, reverse }) => {
  return (
    <section className={classes.section}>
      <div
        className={`${classes["article__content"]}${
          reverse ? ` ${classes.reverse}` : ""
        }`}
      >
        <div className={classes.image}>
          <img src={imageUrl} alt={title} />
        </div>
        <article className={classes.article}>
          <h1 className={classes.title}>{title}</h1>
          <p className={classes.description}>{description}</p>
          <Button label="Get started" />
        </article>
      </div>
    </section>
  );
};

GetStarted.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.node,
  reverse: PropTypes.bool,
};

export default GetStarted;
