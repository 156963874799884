import React from "react";
import { Button, Input, SubTitle, Title } from "../../UI";
import Card from "../../UI/Card/Card";
import classes from "./Contact.module.css";
import ContactImage from "./../../../assets/images/contact.png";
import { FaArrowRight } from "react-icons/fa";

const Contact = (props) => {
  return (
    <section className={classes.contact}>
      <Title text={props.title} />
      <SubTitle text={props.subTitle} />
      <Card>
        <div className={classes.form}>
          <img src={ContactImage} alt="Contact" />

          <form>
            <Input label="Name" />
            <Input label="Contact" />
            <Input label="Message" />

            <Button icon={<FaArrowRight />}>Submit</Button>
          </form>
        </div>
      </Card>
    </section>
  );
};

export default Contact;
