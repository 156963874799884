import React from "react";
import PropTypes from "prop-types";
import classes from "./Avatar.module.css";

const Avatar = (props) => {
  return <div className={classes.avatar}></div>;
};

Avatar.propTypes = {
    src: PropTypes.string,
};

export default Avatar;
