import React from "react";
import classes from "./Footer.module.css";
import Logo from "./../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes["footer__top"]}>
        <div className={classes.intro}>
          <img src={Logo} alt="Logo" />
          <p>
            With you all the way to ensure you have access to quality healthcare
            at a click of a button.
          </p>
        </div>
        <div className={classes["site-map"]}>
          <h3>Site map</h3>
          <ul>
            <li>About us</li>
            <li>Our features</li>
            <li>Testimonials</li>
            <li>Contact us</li>
            <li>Download App</li>
          </ul>
        </div>
        <div className={classes.legal}>
          <h3>Legal</h3>
          <ul>
            <li> <Link to="terms-conditions">Terms & Conditions</Link></li>
            <li>
              <Link to="privacy-policy">Privacy Policy</Link>
            </li>
            <li>Cookies Policy</li>
          </ul>
        </div>
        <div className={classes.follow}>
          <h3>Follow us</h3>
          <ul>
            <li>
              <Link>
                <FaFacebook />
              </Link>
            </li>
            <li>
              <Link>
                <FaTwitter />
              </Link>
            </li>
            <li>
              <Link>
                <FaInstagram />
              </Link>
            </li>
            <li>
              <Link>
                <FaLinkedinIn />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div
        className={classes["footer__bottom"]}
      >&copy;{`Copyright. Syren ${new Date().getFullYear()}. All rights reserved.`}</div>
    </footer>
  );
};

export default Footer;
