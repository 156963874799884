import React from "react";

import classes from "./../components/PrivacyPolicy/PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={classes["privacy-policy"]}>
      <h1>Privacy Policy</h1>
      <blockquote>
        <em>
          “Syren understands how important the privacy of personal information
          is to our users. We understand that your privacy matters and we
          reverence your privacy choices. We developed this Privacy Policy to
          explain how we use, collect, and disclose information from and/or
          about you when you use the Site or the Services.”{" "}
        </em>
      </blockquote>
      <h3>PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING THIS SITE.</h3>
      <ol>
        <li>
          <h4>i. Definitions.</h4>
          <ul>
            <li>
              “Personal Information”: means information about you that is
              personally identifiable to you, such as your contact information
              (e.g., name, address, phone number, email address), personally
              identifiable health or medical information (“Health Information”),
              and any other non-public information that is associated with such
              information (collectively, “Personal Information”).
            </li>
            <li>
              “De-Identified Information”: means information that is neither
              used nor intended to be used to personally identify an individual
              (you).
            </li>
            <li>
              “Cookies”: means the small pieces of information that a Site sends
              to your browser while you are viewing a website. THE SITE WILL BE
              COLLECTING, STORING AND TRANSMITTING PERSONAL, MEDICAL AND
              HEALTH-RELATED INFORMATION ABOUT YOU. BY USING THE SITE, YOU
              APPROVE THAT WE CAN COLLECT AND USE YOUR PERSONAL AND OTHER
              INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. IF YOU DO NOT
              AGREE, PLEASE DO NOT USE THE SITE.
            </li>
          </ul>
        </li>
        <li>
          <h4>II. Children under age 18.</h4>
          <p>
            We do not knowingly allow individuals under the age 18 to create
            Accounts that allow access to our Site.
          </p>
        </li>

        <li>
          <h4>
            III. The Personal information we collect or maintain may include:
          </h4>
          <ul>
            <li>
              Your name, age, email address, phone number username, password,
              and other registration information;• Health information about you
              prepared by the treating Provider(s) who provide the Services
              through the Site such as medical records, treatment and
              examination notes, and other health related information;
            </li>
            <li>
              Health Information that you provide us, which may include
              information or records relating to your medical or health history,
              diagnostic images, health status and laboratory testing results
              and other health related information;
            </li>
            <li>
              Billing information that you provide us, such as credit card
              information
            </li>
            <li>
              Information about the computer or mobile device you are using,
              such as what Internet browser you use, the kind of computer or
              mobile device you use, and other information about how you use the
              Site
            </li>
            <li>
              Other information you input into the Site or related services IV.
              How We May Collect Your Information.
            </li>
            <li>
              When you sign-up for newsletters or other communications from
              Syren.
            </li>
            <li>
              When you use certain interactive tools and services (e.g., The
              Body Mass Index Calculator, the Ovulation Calculator, Vitals chart
              etc.)
            </li>
            <li>
              When you register for or update an existing profile on
              www.syrenapp.com or mobile Apps.
            </li>
            <li>
              When you provide personal information in a public forum or other
              community area, or
            </li>
            <li>When you participate in an online survey.</li>
          </ul>
        </li>

        <li>
          <h4>IV. How We May Collect Your Information.</h4>
          <ul>
            <li>
              When you sign-up for newsletters or other communications from
              Syren.
            </li>
            <li>
              When you use certain interactive tools and services (e.g., The
              Body Mass Index Calculator, the Ovulation Calculator, Vitals chart
              etc.)
            </li>
            <li>
              When you register for or update an existing profile on
              www.syrenapp.com or mobile Apps.
            </li>
            <li>
              When you provide personal information in a public forum or other
              community area, or
            </li>
            <li>When you participate in an online survey.</li>
          </ul>
        </li>

        <li>
          <h4>V. De-Identified Information.</h4>
          <p>
            We may use De-Identified Information created by us without
            restriction.
          </p>
        </li>

        <li>
          <h4>
            VI. Subject to applicable legal restrictions, we may use your
            Personal Information for the following purposes:{" "}
          </h4>
          <ul>
            <li>
              To create De-identified Information such as aggregate statistics
              relating to the use of the Service;
            </li>
            <li>
              To provide you with the Services;• To notify you when Site updates
              are available;
            </li>
            <li>
              To improve the quality of healthcare through the performance of
              quality reviews and similar activities;
            </li>
            <li>
              To achieve any other purpose for which you provide us Personal
              Information;
            </li>
            <li>
              To market and promote the Site and the Services to users (you);
            </li>
            <li>For any other purpose for which you give us approval.</li>
          </ul>
        </li>
        <li>
          <h4>VII. Modification of Information.</h4>
          <p>
            Users will be able to update some of their information through the
            Site. Desire to amend any information may also be submitted directly
            to support@syrenapp.com
          </p>
        </li>
        <li>
          <h4>
            VIII. Your Personal Information collected or provided by you may be
            disclosed (subject to applicable legal restrictions):
          </h4>
          <p>
            Users will be able to update some of their information through the
            Site. Desire to amend any information may also be submitted directly
            to support@syrenapp.com
          </p>
          <ul>
            <li>
              To sponsors, contractors, service providers and other third
              parties we use to support our business and who are bound by
              contractual responsibilities to keep personal information
              confidential and use it only for the purposes for which we
              disclose it to them.
            </li>
            <li>To our partners and affiliates.</li>
            <li>
              As required by law, which can include providing information as
              required by a court order.
            </li>
            <li>
              When we believe in good faith that revelation is necessary to
              protect your safety or the safety of others, to protect our
              rights, to investigate fraud, or to respond to a government
              request.
            </li>
            <li>
              To a buyer or other successor in the event of a divestiture,
              merger, restructuring, reorganization, dissolution or other sale
              or transfer of some or all of Syren’s assets, whether as a going
              concern or as part of bankruptcy, liquidation or similar
              proceeding, in which Personal Information maintained by the Site
              is among the assets transferred.
            </li>
            <li>
              For any other purpose revealed by us when you provide the
              information.
            </li>
          </ul>
        </li>
        <li>
          <h4>IX. Information We Collect via Technology.</h4>
          <ul>
            <li>
              Cookies: Both persistent Cookies or session Cookies (which expire
              once you close your web browser) to make the Site and Service
              easier to use, to protect both you and Syren and to make our
              advertising improved. You can instruct your browser, by changing
              its options, to stop accepting Cookies or to prompt you before
              accepting a Cookie from the websites you visit. If you do not
              accept Cookies, however, you will not be able to stay logged in to
              the Site.{" "}
            </li>
            <li>
              Site Action Information: We may save track of some of the actions
              you take on the Site, such as the content of searches you perform
              on the Site.
            </li>
            <li>
              Access Device and Browser Information: When you access the Site
              from a computer or other device, we may collect anonymous
              information from that device, such as the IP (Internet protocol)
              address, connection speed, browser type and access times
              (collectively, “Anonymous Information”).
            </li>
            <li>
              Real-Time Position: Certain features of the Site use GPS
              technology to collect real-time information about the location of
              your device so that the Site can connect you to a treating
              provider who is licensed or authorized to provide services in the
              state where you are located.
            </li>
            <li>
              Google Analytics: We use Google Analytics to help analyze how
              users use the Site. Google Analytics uses Cookies to collect
              information such as how often users visit the Site, what other
              sites they used prior to coming to the Site and what pages they
              visit on our sites and other activities carried out on how site.
              Information gotten from this is strictly used to improve our
              services and site. Google Analytics collects only the IP address
              allocated to you on the date you visit the Site, rather than your
              name or other personally identifying information. Although Google
              Analytics plants a persistent Cookie on your web browser to
              recognize you as a unique user the next time you visit the Site,
              the Cookie cannot be used by anyone but Google. Google’s ability
              to use and share information gathered by Google Analytics about
              your visits to the Site is restricted by the Google Analytics
              Terms of Use and the Google Privacy Policy.
            </li>
            <li>
              Mobile Services: We may also collect non-personal information from
              your computer and/or mobile device. This information is mostly
              used to help us deliver the most relevant information to you.
              Examples of information that may be collected and used comprise
              how you use the application(s) and information about the type of
              device or computer you use. Furthermore, in the event our
              application(s) crashes on your mobile device we will receive
              information about your mobile device model software version and
              device carrier, which enables us to identify and fix bugs and
              otherwise improve the performance of our application(s).
            </li>
          </ul>
        </li>
        <li>
          <h4>X. Information You Share With Third Parties</h4>
          <p>
            We are not responsible for the privacy practices of other sites, and
            we implore you to read their privacy statements. This Privacy Policy
            applies only to information we gather through the Site and in email,
            text and other electronic communications set through or in
            connection with the Site. This policy DOES NOT apply to information
            gathered by any third party. When you click on links on the Site you
            may leave our site.
          </p>
        </li>
        <li>
          <h4>XI. Report Violations.</h4>
          <p>
            You should report any security violations to us by sending an email
            to support@Syrenapp.com
          </p>
        </li>
        <li>
          <h4>XII. Limitations on Deletion of Information.</h4>
          <p>
            You may request erasure of your Personal Information by us, but
            please note that we may be obliged (by law or otherwise) to keep
            this information and not delete it (or to keep this information for
            a certain time, in which case we will comply with your deletion
            request only after we have fulfilled such requirements). After we
            erase Personal Information, we will retain De-Identified Data and
            will continue to use De-Identified Data as permitted under this
            Privacy Policy. When we delete Personal Information, it will be
            deleted from the active database, but may linger in our archives and
            we may also retain Anonymous Information about your use of our
            Service. Once we disclose some of your Personal Information to third
            parties, we may not be able to access that Personal Information any
            longer and cannot force the deletion or modification of any such
            information by the parties to whom we have made those disclosures.
          </p>
        </li>

        <li>
          <h4>XIII. Steps we take to keep your information secure.</h4>
          <p>
            We employ reasonable physical, electronic and managerial security
            methods to help protect against unauthorized access to Personal
            Information. But please be informed that no data transmission over
            the Internet or data storage facility can be guaranteed to be
            perfectly secure. As a result, while we try to protect your Personal
            Information, we cannot ensure or guarantee the security of any
            information you communicate to us, and you do so at your own risk.
          </p>
        </li>

        <li>
          <h4> XIV. Changes to this Privacy Policy.</h4>
          <p>
            We may alter this Privacy Policy from time to time in the future.
            Any revised version of the Privacy Policy would be made available on
            this page. Continual use of our Service following notice of such
            changes will indicate your acknowledgement of such changes and
            agreement to be bound by the terms and conditions of such changes.
            By using the Site, you are consenting to our collection, use and
            disposal of Personal Information and other data as described in this
            Privacy Policy, both as it exists now and as it is changed from time
            to time.
          </p>
        </li>

        <li>
          <h4>
            XV. Questions. If you have questions or concerns about this Privacy.
          </h4>
          <p>
            Policy, please contact us by sending an email to
            support@syrenapp.com.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
