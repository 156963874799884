import React from "react";
import PropTypes from "prop-types";
import classes from "./Section.module.css";
import { FaRegCheckCircle } from "react-icons/fa";

const Section = ({ title, description, list, imageUrl, reverse }) => {
  return (
    <section
      className={`${classes.section}${reverse ? ` ${classes.reverse}` : ""}`}
    >
      <div className={classes.image}>
        <img src={imageUrl} alt={title} />
      </div>
      <article className={classes.article}>
        <h1 className={classes.title}>{title}</h1>
        <p className={classes.description}>{description}</p>
        {list && (
          <ul>
            {list.map((item) => (
              <SectionListItem text={item} />
            ))}
          </ul>
        )}
      </article>
    </section>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  list: PropTypes.array,
  imageUrl: PropTypes.node,
  reverse: PropTypes.bool,
};

const SectionListItem = (props) => {
  return (
    <li className={classes["section-list-item"]}>
      <FaRegCheckCircle style={{ fontSize: "2rem", marginRight: "1rem", color: '#1B1C1E' }} />
      <span>{props.text}</span>
    </li>
  );
};

SectionListItem.propTypes = {
  text: PropTypes.string,
};

export default Section;
