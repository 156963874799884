import React from "react";
import { NavLink } from "react-router-dom";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import { Button, Logo } from "../../UI";
import classes from "./Header.module.css";

const Header = () => {
  return (
    <header className={classes.header}>
      <NavLink
        style={{
          display: "inline-flex",
          alignItems: "center",
          // flex: "1 1 25%",
        }}
        to="/"
      >
        <Logo className="" />
      </NavLink>
      <nav className={classes.nav}>
        <ul className={classes["nav-list"]}>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <a href="#about">About us</a>
          </li>
          <li>
            <a href="#features">Our features</a>
          </li>
          <li>
            <a href="#testimonials">Testimonials</a>
          </li>
          <li>
            <a href="#contact">Contact us</a>
          </li>
        </ul>
      </nav>
      <Button label="Get started" />
      <button className={classes["btn-mobile-nav"]}>
        <IoMenuOutline
          className={`${classes["icon-mobile-nav"]} ${classes["menu-outline"]}`}
        />
        <IoCloseOutline
          className={`${classes["icon-mobile-nav"]} ${classes["close-outline"]}`}
        />
      </button>
    </header>
  );
};

export default Header;
