import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { Avatar, Card, SubTitle, Title } from "../../UI";
import SpiralImage from "./../../../assets/images/left-spiral.png";
import classes from "./Testimonials.module.css";

const Testimonials = (props) => {
  return (
    <section className={classes.testimonials}>
      
      <div className={classes["testimonials__content"]}>
        <Title text={props.title} />
        <SubTitle text={props.subTitle} />
        <div className={classes.comments}>
          {testimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.id} data={testimonial} />
          ))}
        </div>
      </div>
      <img className={classes.spiral} src={SpiralImage} alt="spiral" />
    </section>
  );
};

const testimonials = [
  {
    id: 1,
    name: "Marylyn Samuel",
    avatar: "https://avatars.githubusercontent.com/u/10000000?v=4",
    comment:
      "Keeping up with my blood pressure has never been easy due to my tight schedule, using syren has made it easier for me to do this and maintain my pressure levels at the same time. ",
  },
  {
    id: 2,
    name: "Barth Ugorji",
    avatar: "https://avatars.githubusercontent.com/u/10000000?v=4",
    comment:
      "I find it easier using syren because i’m able to book appointments with medical practitioners at ease, and also i’m able to schedule appointments at any diagnostics centers of my choice.",
  },
  {
    id: 2,
    name: "Barth Ugorji",
    avatar: "https://avatars.githubusercontent.com/u/10000000?v=4",
    comment:
      "I find it easier using syren because i’m able to book appointments with medical practitioners at ease, and also i’m able to schedule appointments at any diagnostics centers of my choice.",
  },
];

export default Testimonials;

const TestimonialCard = (props) => {
  return (
    <Card className={classes.card}>
      <div className={classes["testimonial-card"]}>
        <div className={classes.header}>
          <Avatar src="" />
          <FaQuoteLeft style={{fontSize:"3.5rem", color: "rgba(0, 0, 0, 0.4)"}} />
        </div>
        <h3 className={classes.name}>{props.data.name}</h3>
        <p>{props.data.comment}</p>
      </div>
    </Card>
  );
};
