import React from "react";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { Button } from "../../UI";
import LeftSpiralImage from "./../../../assets/images/left-spiral.png";
import RightSpiralImage from "./../../../assets/images/right-spiral.png";
import CtaImage from "./../../../assets/images/cta-blur.png";
import classes from "./Cta.module.css";

const Cta = () => {
  return (
    <div className={classes.cta}>
      <img
        className={classes["spiral-left"]}
        src={LeftSpiralImage}
        alt="Left spiral"
      />
      <img
        className={classes["spiral-right"]}
        src={RightSpiralImage}
        alt="Right spiral"
      />
      <article>
        <h2>Healthcare is now closer and more affordable</h2>
        <p>
          Syren helps you and your loved ones get and stay healthy by giving you
          access to a range of tailor-made affordable healthcare services
        </p>
        <div className={classes.actions}>
          <Button label="Google Play" icon={<FaGooglePlay />} gapRight="2rem" />
          <Button label="Apple Store" icon={<FaApple />} />
        </div>
      </article>
      <div className={classes.image}>
        <img  src={CtaImage} alt="Call to action" />
      </div>
    </div>
  );
};

export default Cta;
