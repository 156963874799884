import React from "react";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { Button } from "../../UI";
import CtaBottomImage from "./../../../assets/images/cta-bottom-image.png";
import classes from "./CtaBottom.module.css";

const CtaBottom = () => {
  return (
    <div className={classes["cta-bottom"]}>
      <div className={classes["cta-bottom__container"]}>
        <img
          className={classes.image}
          src={CtaBottomImage}
          alt="Call to Action"
        />

        <article>
          <h4>DOWNLOAD</h4>
          <h2>Take control of your health today with Syren</h2>
          <div className={classes.actions}>
            <Button label="Google Play" icon={<FaGooglePlay style={{}} />} white />
            <Button label="Apple Store" icon={<FaApple style={{}}/>} white />
          </div>
        </article>
      </div>
    </div>
  );
};

export default CtaBottom;
