import React from "react";

import classes from "./../components/PrivacyPolicy/PrivacyPolicy.module.css";

const TermsAndConditions = () => {
  return (
    <div className={classes["privacy-policy"]}>
      <h1>
        PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING
        THIS SITE.
      </h1>
      <blockquote>
        <em>
          " By using this site, you signify your assent to these Terms and
          Conditions. If you do not agree to all of these Terms and Conditions
          of use, do not use this site! Syren may revise and update these Terms
          and Conditions at any time. Your continued usage of www.Syrenapp.com
          will mean you accept those changes. "
        </em>
      </blockquote>
      <div>
        <h3>i. Definitions. </h3>
        <ul>
          <li>
            Knerv Limited: is the company that runs Syren and may be used
            interchangeably with “Syren”, “We”, “us”, or “our”.
          </li>
          <li>
            The Syren mobile application, www,syrenapp.com may be
            interchangeably used.
          </li>
          <li>
            Tele health Services: any form of medical consultation,
            counselling/advice, intervention given via technological means
            (texting, audio, video and others). May be interchangeably used with
            “services”, “healthcare services”.
          </li>
          <li>
            Users: may be interchangeably used with “you”, “clients”, “members”,
            “visitors”. Treating Providers: referring to doctors that offer
            services on our site. May be replaced by “healthcare Professionals”.
          </li>
          <li>
            Account/Account Information: Account refers to your personal
            information used in setting up your user’s profile. Account
            information refers to your name, age, email address, password, phone
            number, home address and other information collected while setting
            up your account.
          </li>
        </ul>
      </div>
      <div>
        <h3>ii. Operations</h3>
        <ul>
          <li>
            Knerv Limited. (“Syren”, “we”, “us”, or “our”) operates the website
            located at www.Syrenapp.com and other related websites and mobile
            applications with links to these Terms of Use (collectively, the
            “Site”).
          </li>
          <li>
            We offer online services that enable users store medical data,
            request for care, order medical items and also access help in form
            of video, audio, text call/chat enabling our users to state their
            health history and interact with healthcare professionals to obtain
            medical and healthcare services. Also, we provide health articles
            for public consumption. By using this site, you signify your consent
            to these Terms and Conditions. If you do not agree to all these
            Terms and Conditions of use, do not use this site!
          </li>
          <li>
            All of the healthcare professionals who convey Services through
            www.syrenapp.com & the Syren mobile application are independent
            professionals exclusively responsible for the services each provides
            to you. Syren does not practice medicine or any other licensed
            profession and does not interfere with the practice of medicine or
            any other licensed profession by Treating Providers, each of whom is
            responsible for his or her services and agreement with the
            requirements applicable to his or her profession and license.
            Neither Syren nor any third parties who promote the Services or
            provide you with a link to the Service shall be liable for any
            professional advice you obtain from a Treating Provider via the
            Services.
          </li>
          <li>
            None of the Site content (other than information/advice you receive
            from Treating Providers) should be considered medical advice or an
            endorsement, representation or warranty that any particular
            medication or treatment is safe, appropriate, or effective for
            users.
          </li>
        </ul>
      </div>

      <div>
        <h3>iii. Informed Consent.</h3>
        <ul>
          <li>
            Telehealth is the delivery of health care services using interactive
            technology such as texting, audio and video technology, where the
            patient and the health care professional are not in the same
            physical location. During your telehealth consultation with a
            Treating Provider, details of your medical or health history and
            personal health information may be discussed with you through the
            use of interactive video, audio, text and other telecommunications
            technology, and your healthcare professional may perform a physical
            examination through these technologies. Depending on your medical or
            health history and/or specific complaint, you may be asked to
            provide information through biometric devices such as blood pressure
            cuffs, glucometer or digital thermometers. As a registered and
            subscribed member, You may also be asked for proof of identity with
            a legal document such as your driver’s license.
          </li>
          <li>
            The Services you receive from Treating Providers are not proposed to
            replace a primary care physician relationship or be your permanent
            medical home. You should seek emergency support or follow-up care
            when mentioned by a Treating Provider or when otherwise needed, and
            continue to consult with your primary care physician and other
            healthcare professionals as recommended. Syren may make arrangements
            for follow up care either through Syren, a health systems partner or
            other healthcare providers. You will have direct access to customer
            support services to follow up on medication reactions, side effects
            or other adverse events. Among the benefits of our Services are
            upgraded access to healthcare professionals and convenience.
            However, as with any medical or health service, there are potential
            hazards associated with the use of telehealth. These risks include,
            but may not be limited to:Occasionally, information transmitted may
            not be satisfactory (e.g. poor resolution of pictures) to allow for
            apt medical or health care decision making by the Treating
            Provider;Delays in evaluation or treatment could occur due to
            failures of the electronic equipment;Although the electronic systems
            we use will integrate network and software security protocols to
            protect the privacy and security of health information, in rare
            cases, security protocols could fail, causing a breach of privacy of
            personal health information• By accepting these Terms of Use, you
            admit that you understand and agree with the following:You
            understand that you may expect the anticipated benefits from the use
            of telehealth in your care, but that no results can be assured or
            guaranteed.You understand that the laws that protect the privacy and
            security of health information apply to telehealth, and have
            received Syren’s Notice of Privacy Practices, which describes these
            protections in more detail.
          </li>
        </ul>
      </div>

      <div>
        <h3>iv. Privacy</h3>
        <p>
          Syren is required to conform to the Nigerian health care privacy and
          security laws and maintain safeguards to protect the security of your
          health information. We dedicate considerable effort toward ensuring
          that your personal information is secure with us. Information
          regarding our use of health and other personal information is provided
          in our Site Privacy Policy and health information Notice of Privacy
          Practices. As part of providing you the Services, we may need to
          provide you with certain communications, such as administrative
          messages, reminders and service announcements. These forms of
          communications are considered part of the Services and your Account.
          Under certain circumstances, insecure email communication containing
          personal health information may take place between you and Syren,
          though we are aware that the secure electronic messaging is always
          preferred to insecure email. Syren cannot ensure the security or
          confidentiality of messages sent by email.
        </p>
      </div>

      <div>
        <h3>v. User Account</h3>
        <p>
          When you register on the Site, you are required to create an account
          by entering your name, phone number, email address, password and
          certain other information collected by Syren. To create an Account,
          you must be of legal age to form a binding contract. If you are not of
          legal age to form a binding contract, you may not register to use our
          Services. You agree that the Account Information that you provide to
          us at all times, including during registration and in any information
          you upload to the Site will be true, accurate, up-to-date, and
          complete. You may not transfer or share your Account password with
          anyone, or create more than one Account (with the exemption of
          subaccounts created for family members). You are responsible for
          maintaining the confidentiality of your Account password and for all
          activities that occur under your Account. Syren reserves the right to
          take any and all action, as it deems necessary or reasonable,
          regarding the security of the Site and your Account Information. In no
          event and under no circumstances shall Syren be held liable to you for
          any liabilities or damages resulting from or arising out of your use
          of the Site, your use of the Account Information or your release of
          the Account Information to a third party. You may not use anyone
          else’s account at any time.
        </p>
      </div>

      <div>
        <h3>vi. Use of the Services by Children</h3>
        <p>
          If you register as the parent or legal guardian on behalf of a child,
          you will be fully responsible for complying with these Terms of Use.
          The Services on the site are available for use by children, but the
          Member for all patients under the age of 18 must be the patient’s
          legal guardian or parent.
        </p>
      </div>

      <div>
        <h3>vii. Access Rights</h3>
        <p>
          We hereby grant to you a limited, non-exclusive, nontransferable right
          to access www.Syrenapp.com and use the Services solely for your
          personal non-commercial use and only as permitted under these Terms of
          Use and any separate agreements you may have entered into with us
          (“Access Rights”). We reserve the right, in our sole discretion, to
          deny or suspend use of the Site, app or Services to anyone for any
          reason at any time. You agree that you will not, and will not attempt
          to:
        </p>
        <ol>
          <li>
            (a) Use the Site or Services to violate any local, state, national
            or international law;
          </li>
          <li>
            (b) Impersonate any person or entity, or otherwise misrepresent your
            affiliation with a person or entity;
          </li>
          <li>
            (c) Decompile, disassemble, reverse engineer, or translate any
            software or other components of the Site or Services;
          </li>
          <li>
            (d) Distribute viruses or other harmful computer code through the
            Site or{" "}
          </li>
          <li>
            (e) Otherwise use the Services or Site in any manner that exceeds
            the scope of use granted above.
          </li>
        </ol>
        <p>
          In addition, you agree to abstain from abusive language when
          communicating with Treating Providers through the Site and to refrain
          from contacting Treating Providers for telehealth services outside
          www.Syrenapp.com. Syren is not responsible or liable for any dealings
          with Treating Providers that are not conducted through the Site. We
          strongly recommend that you do not use the Services on public
          computers or cyber cafes. We also recommend that you do not store your
          Account password through your web browser or other software.
        </p>
      </div>

      <div>
        <h3>viii. Website Links</h3>
        <p>
          SYREN WILL NOT BE LIABLE FOR ANY INFORMATION, LINKS, OR SOFTWARES
          FOUND AT ANY OTHER INTERNET LOCATION, SOURCE OF INFORMATION, OR
          WEBSITE, NOR FOR YOUR USE OF SUCH LINKS, SOFTWARE OR INFORMATION, NOR
          FOR THE ACTS OR OMISSIONS OF ANY SUCH WEBSITES OR THEIR RESPECTIVE
          OPERATORS.
        </p>
      </div>

      <div>
        <h3>ix. Ownership</h3>
        <p>
          www.Syrenapp.com and its entire contents, functionality and features
          (including but not limited to all information, text, software,
          displays, images, video and/or audio, and the design, selection and
          arrangement thereof), are owned by Knerv Limited, its licensors or
          other providers of such material and are protected by Federal Republic
          Of Nigeria and international copyright, trademark, patent, trade
          secret and other intellectual property or proprietary rights laws.
          These Terms of Use permit you to use the Site for your personal,
          non-commercial use only. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store or transmit any of the material on our Site
          except as generally and ordinarily permitted through the Site
          according to these Terms of Use. Any part of the Site or any services
          or materials available through the Site MUST NOT be accessed or used
          for any commercial purpose(s).
        </p>
      </div>

      <div>
        <h3>x. Trademarks</h3>
        <p>
          Certain of the names, logos, and other materials exhibited on the Site
          or in the Services may constitute trademarks, service marks, trade
          names or logos (“Marks”) of Syren or other bodies. You are not
          authorized to use any such Marks without the express written
          permission of Knerv LTD. Ownership of all such Marks and the goodwill
          associated therewith remains with Syren or those other entities.
        </p>
      </div>

      <div>
        <h3>xi. Termination</h3>
        <p>
          You may deactivate your Account and end your registration at any time,
          for any reason by sending an email to support@syrenapp.com. Syren may
          suspend or terminate your use of the Site/mobile application, your
          Account and/or registration for any reason at any time. Subject to
          applicable law, Syren reserves the right to maintain, delete or
          destroy all communications and materials posted or uploaded to the
          Site pursuant to its internal record retention and/or content
          destruction policies. After such termination, Syren will have no
          additional obligation to provide the Services, except to the extent we
          are obligated to provide you access to your health records or Treating
          Providers are obliged to provide you with continuing care under their
          applicable legal, ethical and professional obligations to you.
        </p>
      </div>

      <div>
        <h3> xii. Right to modify</h3>
        <p>
          We may at our solitary discretion change, add, or delete portions of
          these Terms of Use at any time on a going-forward basis. Continual use
          of the Site and/or Services following notice of any such changes will
          indicate your acknowledgement of such changes and agreement to be
          bound by the revised Terms of Use, inclusive of such changes.
        </p>
      </div>

      <div>
        <h3> xiii. Disclaimer Of Warranties.</h3>
        <p>
          YOU EXPRESSLY AGREE THAT USE OF THE SITE OR SERVICES IS AT YOUR SOLE
          RISK. BOTH THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS. Syren EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY
          KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR
          PURPOSE, NON-INFRINGEMENT, TITLE, OPERABILITY, CONDITION, QUIET
          ENJOYMENT, VALUE, ACCURACY OF DATA AND SYSTEM INTEGRATION.You
          acknowledge and agree that Syren does not provide medical advice,
          diagnosis, or treatment, and is strictly a technology platform and
          infrastructure for connecting patients with independent third party
          Treating Providers, including physicians and other Treating Providers
          in the Syren network. You acknowledges and agree that the Treating
          Providers using the Site are solely responsible for and will have
          complete authority, responsibility, supervision, and control over the
          provision of all medical services, advice, instructions, treatment
          decisions, and other professional health care services performed, and
          that all diagnoses, treatments, procedures, and other professional
          health care services will be provided and performed exclusively by or
          under the supervision of Treating Providers, in their sole discretion,
          as they deem appropriate.
        </p>
      </div>

      <div>
        <h3> xiv. Limitation Of Liability.</h3>
        <p>
          YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN
          NO EVENT WILL SYREN OR ITS DIRECTORS, SUBSIDIARIES, OFFICERS,
          AFFILIATES, EMPLOYEES, AGENTS, PARENTS OR LICENSORS BE LIABLE FOR ANY
          INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
          INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES, PROFITS,
          GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES ARISING OUT OF OR
          RELATED TO YOUR USE OF THE SITE OR THE SERVICES, REGARDLESS OF WHETHER
          SUCH DAMAGES ARE BASED ON CONTRACT, TORT (INCLUDING STRICT LIABILITY
          AND NEGLIGENCE), WARRANTY, STATUTE OR OTHERWISE. TO THE EXTENT THAT WE
          MAY NOT, AS A MATTER OF APPLICABLE LAW, DISCLAIM ANY IMPLIED WARRANTY
          OR LIMIT ITS LIABILITIES, THE SCOPE AND DURATION OF SUCH WARRANTY AND
          THE EXTENT OF OUR LIABILITY WILL BE THE MINIMUM PERMITTED UNDER SUCH
          APPLICABLE LAW.
        </p>
      </div>

      <div>
        <h3> xv. Indemnification.</h3>
        <p>
          You agree to indemnify, defend and hold harmless your Treating
          Provider(s) from and against any third party Claims ensuing from your
          lack of adherence with the advice or recommendation(s) of such
          Treating Provider. Also, You agree to indemnify, defend and hold
          harmless Syren, it’s directors, subsidiaries, officers, affiliates,
          employees, agents, licensors and suppliers, harmless from and against
          any claim, demands, actions, liabilities and settlements, including
          without limitation reasonable legal and accounting fees (“Claims”),
          resulting from, or alleged to result from, your violation of these
          terms and conditions.
        </p>
      </div>

      <div>
        <h3> xvi. Geographical Restrictions </h3>
        <p>
          Syren makes no representation that all products, services and/or
          material described on the Site, or the Services available through the
          Site, are appropriate or available for use in locations outside the
          Federal Republic of Nigeria or all territories within the Federal
          Republic of Nigeria.
        </p>
      </div>

      <div>
        <h3> xvii. Miscellaneous </h3>
        <p>
          These Terms of Use and your use of the Site shall be governed by the
          laws of the State of Lagos, without giving effect to the principles of
          conflict of laws. Any dispute arising under or relating in any way to
          these Terms of Use will be resolved exclusively by final and binding
          arbitration in Lagos, under the rules of the Nigerian Arbitration
          Association, except that either party may bring a claim related to
          intellectual property rights, or seek temporary and preliminary
          specific performance and injunctive relief, in any court of competent
          jurisdiction, without the posting of bond or other security. The
          parties agree to the personal and subject matter jurisdiction and
          venue of the courts located in Lagos, for any action related to these
          Terms of Use.Your understand that by checking the “agree” box for
          these Terms of Use and/or any other forms presented to you on the Site
          you are agreeing to these Terms of Use and that such action
          constitutes a legal signature. You agree that we may send to you any
          privacy or other disclosures, notices, or communications regarding the
          Services (collectively, “Communications”) through electronic means
          including but not limited to:
        </p>
        <ol>
          <li>
            1) By SMS, using the phone number you provided to Syren while
            registering;
          </li>
          <li>
            2) By e-mail, using the email address that you provided to us during
            registration or
          </li>
          <li>3) By posting the Communications on the Site.</li>
        </ol>
        <p>
          The delivery of any Communications from Syren is effective when sent
          by us, regardless of whether you read the Communication when you
          receive it or whether you actually receive the delivery of such
          communication. You can withdraw your consent to receive Communications
          by email/SMS by canceling or discontinuing your use of the Service.No
          waiver by the Company of any term or condition set forth in these
          Terms of Use shall be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect. Syren
          dedicates considerable effort to improving diagnosis deficiencies but
          is not responsible for the internet or data bandwidth and signal of
          your mobile device.Please report any violations of these Terms of Use
          to support@Syren.com.ng.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
