import React from "react";
import { Outlet } from "react-router-dom";
import { Footer, Header } from "../components/Layout";

const RootLayout = () => {
  return (
    <>
      <Header />
      <main id="main">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default RootLayout;
